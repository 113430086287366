<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null && created == null" style="margin-bottom: 30px">
          <v-card-title>
            Partners
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <b-button type="button" @click="clicked2()" variant="primary">New Partner</b-button>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="partners"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>
      </div>

      <div v-if="created">
        <b-card class="mt-12" header="Add new partner">
          <b-row>
            <b-col>
              <b-form-group id="input-group-2" label="Name Partner:" label-for="input-2">
                <b-form-input id="input-2" v-model="name_partner"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-2" label="Email Partner:" label-for="input-2">
                <b-form-input id="input-2" v-model="email_partner"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-2" label="Phone Partner:" label-for="input-2">
                <b-form-input id="input-2" v-model="phone_partner"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-2" label="Password Partner:" label-for="input-2">
                <b-form-input id="input-2" v-model="password_partner"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-2" label="City License:" label-for="input-2">
                <b-form-select v-model="selectedCity" :options="optionsCity"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            type="button"
            @click="created = null"
            variant="danger"
            style="margin-right: 20px;"
          >Return</b-button>
          <b-button type="button" @click="newPartner()" variant="primary">Save</b-button>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "partners",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },

    clicked2() {
      this.created = 1;
      console.log(this.created);
      console.log("oi");
    },
    newPartner() {
      fetch(
        "https://cleancity.krueltech.com/dev/api/api.php?action=newPartner&name=" +
          this.name_partner +
          "&email=" +
          this.email_partner +
          "&phone=" +
          this.phone_partner +
          "&password=" +
          this.password_partner +
          "&city=" + this.selectedCity
      )
        .then(window.location.reload())
        .catch(console.log);
    }
  },
  data() { 
    return {
      partners: [],
      worker: [],
      workerBackup: [],
      orderAtual: null,
      name_partner: "",
      email_partner: "",
      phone_partner: "",
      password_partner: "",
      selectedCity: "",
      optionsCity: [],
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      created: null,
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "City", value: "city" }
      ]
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if (auth != null && auth != "undefined") {
      console.log("existe");
    } else {
      console.log("nao existe");
      this.$router.push({ name: "login" });
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ])

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readPartners")
      .then(res => res.json())
      .then(data => {
        this.partners = data.partners;
        console.log(this.partners);
      })
      .catch(console.log)

      fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readCities")
      .then(res => res.json())
      .then(data => {
        this.optionsCity = data.citiesName
      })
      .catch(console.log)
  }
};
</script>
